import { apiService } from '@/services/api-services'
import { rules } from '@/plugins/rules'
import { walletStore } from '@/stores/wallet-store'
import { observable, action } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { appProvider } from '@/app-provider'

export class RegisterViewModel {
  @observable emailAddress = ''
  @observable emailAddressError = false

  @observable username = ''
  @observable usernameError = false

  @observable agreePolicy = false
  @observable agreePolicyError = false

  constructor() {
    //
  }

  @asyncAction *check() {
    yield walletStore.checkUserWallet()
    if (walletStore.isRegisted) {
      appProvider.router.push('/')
    }
  }

  @action.bound onEmailAddressChange(val: string) {
    if (this.emailAddressError) this.emailAddressError = false
    this.emailAddress = val
  }

  @action.bound onUsernameChange(val: string) {
    if (this.usernameError) this.usernameError = false
    this.username = val
  }

  @action.bound onAcceptTermChange(val: boolean) {
    if (this.agreePolicyError) this.agreePolicyError = false
    this.agreePolicy = !this.agreePolicy
  }

  @asyncAction *register() {
    const isValidation = yield this.validation()
    if (!isValidation) {
      return
    } else {
      walletStore.createUserProfile({email: this.emailAddress, userName: this.username})
    }
  }

  @asyncAction *validation() {
    const userEmail = yield apiService.users.find({ email: this.emailAddress })
    const isEmailExisted = userEmail.length
    if (
      rules.required(this.emailAddress) === 'This is required field' ||
      rules.email(this.emailAddress) === 'Wrong format' ||
      isEmailExisted
    ) {
      if (isEmailExisted) snackController.error("Email is existed")
      this.emailAddressError = true
    }

    if (rules.required(this.username) === 'This is required field') this.usernameError = true

    if (!this.agreePolicy) this.agreePolicyError = true

    return !(this.emailAddressError || this.usernameError || this.agreePolicyError)
  }
}
