


































































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { RegisterViewModel } from '../viewmodels/register-viewmodel'

@Observer
@Component({
  components: {},
})
export default class Register extends Vue {
  @Provide() vm = new RegisterViewModel()
  wallet = walletStore

  mounted() {
    this.vm.check()
  }
}
